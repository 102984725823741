import React from "react"

import SEO from "../components/seo"
import { css } from "@emotion/core"
import posed from "react-pose"
import Content from "../components/Content"
import Sunrise from "../components/Sunrise"
import Footer from "../components/Footer"
import Thumb from "../images/svg/like.svg"
import Header from "../components/header"
import Gallery from "../components/Gallery"
import Jocke from "../components/Jocke"
require("typeface-rubik")
const Container = posed.div({
  initial: { x: 200, opacity: 0 },
  finished: { x: 0, opacity: 1 },
})
var mobileWidth = "640px"

const AnimateSvg = posed.div({
  initial: { rotateZ: -50, opacity: 0 },
  finished: {
    rotateZ: 0,
    opacity: 1,
    transition: { type: "spring", duration: 400, stiffness: 100 },
  },
})

class Home extends React.Component {
  state = { pose: "initial" }

  render() {
    return (
      <div>
        <Header  />
        <SEO title="Hem" keywords={[`Wahman`, `Utvecklare`, `Gatsby`, `Pose`]} />
        <section
          ref={this.ref}
          id="intro"
          css={css`
            min-height: 100vh;
            background: #3b8beb;
            position: relative;
          `}
        >
          <Content>
        <Container css={{opacity: 0}} pose={this.state.pose}>
            <h1
              css={css`
                color: white;
                text-align: center;
                font-size: 3.5rem;
              `}
            >
              Halloj!
            </h1>
            </Container>
            <Jocke />
            <Container
              pose={this.state.pose}
              css={css`
                @media screen and (max-width: ${mobileWidth}) {
                  width: 100%;
                }
                opacity: 0;
                width: 30rem;
                color: white;
              `}
            >
              <h4
                css={css`
                @media screen and (max-width: ${mobileWidth}) {
                 font-size: 1.75rem;
                }
                  font-size: 2.5rem;
                  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
                `}
              >
                Utvecklare, Team leader och världens stoltaste pappa!
              </h4>
              <p
                css={css`
                  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
                `}
              >
              Joakim Wahman heter jag och är 28 år gammal och både jobbar och brinner för utveckling! Här hittar du lite smått och gott om mig så som kunskaper inom programmering samt en liten tidslinje om allt kul som hänt mig! Skulle du vilja komma i kontakt med mig så hittar ni kontaktinfo längst ner på sidan!
              </p>
            </Container>
            <AnimateSvg
              css={css`
                position: absolute;
                z-index: 0;
                right: 5rem;
                bottom: 5rem;
                @media screen and (max-width: ${mobileWidth}) {
                  position: relative;
                  text-align: right;
                  bottom: 0;
                  right: 1rem;
                }
              `}
              pose={this.state.pose}
            >
              <Thumb
                css={css`
                @media screen and (max-width: ${mobileWidth}){
                  width: 5rem;
                }
                  width: 10rem;
                  height: auto;
                `}
              />
            </AnimateSvg>
          </Content>
        </section>
        <Sunrise />
        <Gallery />
        <Footer />
      </div>
    )
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ pose: "finished" })
    }, 500)
  }
}

export default Home
