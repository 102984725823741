import React from "react"
import { css } from "@emotion/core"
import posed from "react-pose"
import Content from "../components/Content"
import Happy from "../components/Happy"
import Wedding from "../components/Wedding"
var mobileWidth = "640px"
const Box = posed.div({
  notPopped: {
    delay: 300,
  },
  popped: {
    delayChildren: 200,
    staggerChildren: 50,
    transition: { duration: 500 },
  },
})
const Item = posed.div({
  popped: {
    opacity: 1,
  },
  passed: {
    opacity: 1,
  },
  notPopped: {
    opacity: 0,
  },
})
const Sun = posed.div({
  popped: {
    opacity: 1,
    y: 0,
    transition: { duration: 700 },
  },
  passed: {
    opacity: 0,
    y: 200,
  },
  notPopped: {
    opacity: 0,
    y: 200,
  },
})
const Lightup = posed.section({
  popped: {
    // background: "rgb(60, 197, 239)",
    background:
      "linear-gradient(to bottom, #3b8beb, #629fef, #83b3f2, #a4c7f4, #c4dbf6)",
    transition: { duration: 700 },
    delay: 300,
  },
  passed: {
    background:
      "linear-gradient(to bottom, #3b8beb, #2f6cba, #224e8b, #13325f, #051937)",
    transition: { duration: 700 },
    delay: 0,
  },
  notPopped: {
    background:
      "linear-gradient(to bottom, #3b8beb, #3b8beb, #3b8beb, #3b8beb, #3b8beb)",
  },
})

class Sunrise extends React.Component {
  state = { showBoxes: "notPopped", scrolledPast: false }
  refBoxes = React.createRef()
  render() {
    return (
      <Lightup
        pose={this.state.showBoxes}
        css={css`
          position: relative;
          margin-top: -0.1rem;
        `}
      >
        {/* scrollat hit */}
        <Content>
          <Box
            ref={this.refBoxes}
            pose={this.state.showBoxes}
            css={css`
              min-height: 100vh;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              div {
                box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
                  0 5px 15px 0 rgba(0, 0, 0, 0.08);
                @media screen and (max-width: ${mobileWidth}) {
                  width: 100%;
                }
                h4 {
                  font-size: 1.75rem;
                  text-align: center;
                  margin-bottom: 0.75rem;
                }
                p {
                  text-align: left;
                  margin-top: 1rem;
                }
                margin-top: 1rem;
                display: flex;
                width: 30%;
                border-radius: 0.25rem;
                background: white;
                flex-direction: column;
                align-items: center;
                padding: 1rem;
              }
            `}
          >
            <Sun
              pose={this.state.showBoxes}
              css={css`
                @media screen and (max-width: ${mobileWidth}) {
                  display: none !important;
                }
                width: 6rem !important;
                height: 6rem !important;
                border-radius: 50% !important;
                background: yellow !important;
                position: absolute;
                top: 10%;
                right: 2rem;
              `}
            />
            <Item>
              <h4>PHP</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >Min största erfarenhet! De flesta uppdrag som görs inom jobbet är Wordpress-relaterade och har därför byggt en stor mängd sidor i detta!
              </p>
            </Item>
            <Item>
              <h4>React</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
               Inte byggt något jobbrelaterat utan har istället varit privat för att komma in i att bygga sidor i JS. Förutom många småprojekt har jag även byggt en app för familjen där vi kunde registrera vår vikt och sedan tävla internt i viktnedgång! (Jag vann inte...)
              </p>
            </Item>
            <Item>
              <h4>Android</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
Anställdes i första hand som androidutvecklare och höll mig till det i ca 2 år. Idag är det nästan helt och hållet fokus på Web istället. Här har jag utvecklat allt från Quiz till sociala flöden.
              </p>
            </Item>
            <Item>
              <h4>Gatsby</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
                Något jag verkligen blivit kär i! Denna sidan är bland annat byggt i det och vi satsar på att gå över till det mer och mer i uppkommande projekt!
              </p>
            </Item>
            <Item>
              <h4>React native</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
                Byggde en Soundboard med bilder på olika släktingar som spelar ett ljudklipp kopplad till denna släkting vid tryck. Väldigt smärtfritt att bygga appar i och extremt omtyckt bland barnen i släkten!
              </p>
            </Item>
            <Item>
              <h4>Cordova</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
                Gjorde ett uppdrag i detta på jobbet för något år sedan. Funkade också ganska smärtfritt men inte lika smutt som React native. Mina roller i uppdraget vi hade var att publicera appen och att vara en del i utvecklingen.
              </p>
            </Item>
            <Item>
              <h4>Svelte</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
                Enbart hobbyprojekt här men tror garanterat att det blir mer av detta. Lätt att komma in i och skapar extremt snabba sidor!
              </p>
            </Item>
            <Item>
              <h4>jQuery</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
                Blivit mycket av detta i samband med PHP/Wordpress.
              </p>
            </Item>
            <Item>
              <h4>Vue</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
                Enbart privat men gillar strukturen väldigt mycket! Hoppas få se mer av detta!
              </p>
            </Item>{" "}
            <Item>
              <h4>SQL</h4>
              <p
                css={css`
                  margin-bottom: 0.5rem;
                  line-height: 1.25em;
                `}
              >
                Blivit mycket av detta i samband med Wordpress. Wordpress sköter det mesta men inte alltför sällan får man skriva custom querys.
              </p>
            </Item>{" "}

          </Box>
        </Content>
        <Wedding />
        <Happy />
      </Lightup>
    )
  }
  componentDidMount() {
    const boxObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          this.setState({ scrolledPast: true })
          this.setState({ showBoxes: "popped" })
        } else if (this.state.scrolledPast) {
          this.setState({ showBoxes: "passed" })
        }
      })
    })

    boxObserver.observe(this.refBoxes.current)
  }
}

export default Sunrise
