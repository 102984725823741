import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import posed from "react-pose"
import HeartsImage from "../images/hearts.png"
const mobileWidth = '640px'
const Hearts = posed.div({
    popped: {
      opacity: 1,
      y: 0,
      transition: { duration: 2000 },
    },
    notPopped: {
      opacity: 0,
      y: 400,
    },
  })
class Wedding extends React.Component {
    state = { showBoxes: "notPopped"};
    ref = React.createRef();
  render() {
    return (
      <StaticQuery
        query={graphql`
        query {
            Wedding: file(relativePath: { eq: "wedding.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <section css={{maxWidth: "1024px", marginLeft: "auto", marginRight: "auto",position: "relative", padding: "2rem", minHeight: "100vh" }}>
            <h3
              css={{
                  textAlign: "center",
                fontSize: "3.25rem",
                color: "white",
                marginTop: "2.5rem",
              }}
            >
              8/7 2017
            </h3>
            <p
              css={{
                color: "white",
                whiteSpace: "pre-line",
                fontSize: "1.25rem",
                textAlign: "center",
              }}
            >
              Gifte mig med min Sara!
            </p>
            <Hearts css={{[`@media screen and (max-width: ${mobileWidth})`]: {display: "none"}, position: "absolute", right: "1em", top: "5rem"}} pose={this.state.showBoxes} ref={this.ref} >
            <img alt="Hearts" src={HeartsImage} />
            </Hearts>
            <Img alt="Wedding" css={{ minHeight: "30rem", margin: "auto",borderRadius: "0.5rem", maxWidth: "600px", textAlign: "center"}} fluid={data.Wedding.childImageSharp.fluid} />
          </section>
        )}
      />
    )
  }
  componentDidMount() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          this.setState({ showBoxes: "popped" })
        } else if (this.state.scrolledPast) {
          this.setState({ showBoxes: "passed" })
        }
      })
    })

     observer.observe(this.ref.current)
  }
}
export default Wedding
