import React from "react"
import { css } from "@emotion/core"
import Twitter from "../images/svg/twitter.svg";
import Facebook from "../images/svg/facebook.svg";
import Linkedin from "../images/svg/linkedin.svg";
import Mail from "../images/svg/mail.svg";
class Footer extends React.Component {
  render() {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          text-align: center;
          background: #8590aa;

          padding: 1.5rem;
          justify-content: center;
        `}
      >
        <h3 css={{fontSize: "1.5rem", color: "white", fontWeight: 600, textShadow: "3px 3px 3px rgba(0, 0, 0, 0.3)"}}>Kontakt</h3>
        <div css={css`
        display: flex; 
        justify-content: center;
        svg{
          margin-left: 0.25rem;
          margin-right: 0.25rem;
          height: 2.5rem;
          width:2.5rem;
        `}>
        <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/MrMagicLove6"><Twitter /></a>
        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/jockewahman"><Facebook /></a>
        <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/jockewahman"><Linkedin /></a>
        <a href="mailto:joakim.wahman@gmail.com"><Mail /></a>
        </div>
      </div>
    )
  }
}

export default Footer
