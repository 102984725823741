import React from "react"
import { css } from "@emotion/core"
import Jockis from "../images/jocke.jpg"

class Jocke extends React.Component {

    render() {
        return (
              <div
                css={css`
                  width: 12rem;
                  height: 12rem;
                  margin-top: 2rem;
                  margin-bottom: 2rem;
                  margin-left: auto;
                  margin-right: auto;
                  border-radius: 50%;
                  overflow: hidden;
                `}
              >
                  <img alt="Jocke" src={Jockis} />
              </div>
        )
      }
}

export default Jocke