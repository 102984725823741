import React from "react"
import { css } from "@emotion/core"
import Content from "../components/Content"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import posed from "react-pose"
var mobileWidth = "640px"
const CenterImage = posed.div({
  false: {
    opacity: 0,
    zIndex: -1,
    transition: {
      duration: 300,
    },
  },
  true: {
    opacity: 1,
    zIndex: 1,
    transition: {
      opacity: { duration: 300 },
      default: { duration: 0 },
    },
  },
})
var allImages = [];
class Gallery extends React.Component {
  state = { activeImage: null, pos: 0, loaded: false, imageOpened: false }

  handleClick(i) {
    if(!this.state.imageOpened){
      this.setState({imageOpened: true});
      this.downloadImages();
    }
    if(i === null){
      this.setState({ activeImage: null })
    }else{
    var imageSrc = allImages[i].node.childImageSharp.fluid;
    if (this.state.activeImage === null) {
      this.setState({ activeImage: imageSrc, pos: i })
    }
  }
  }
  changeImage(option){
    var val = this.state.pos + option;
    if(val > allImages.length-1){
      val = 0;
    }else if(val < 0){
      val = allImages.length-1;
    }
    this.setState({activeImage: allImages[val].node.childImageSharp.fluid, pos: val})
  }
  downloadImages(){
    allImages.forEach((img, i) => {
      console.log(img.node);
        const imagebaby = new Image();
        imagebaby.sizes = img.node.childImageSharp.fluid.sizes;
        imagebaby.srcset = img.node.childImageSharp.fluid.srcSet;
        imagebaby.srcSetWebp = img.node.childImageSharp.fluid.srcSetWebp;
        imagebaby.src = img.node.childImageSharp.fluid.src;
        imagebaby.srcWebp = img.node.childImageSharp.fluid.srcWebp;
    });
  }
  
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allFile(filter: { relativeDirectory: { eq: "veris" } }) {
              edges {
                node {
                  id
                  name
                  publicURL
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    },
                  }
                }
              }
            }
          }
        `}
        render={data => {

          allImages = data.allFile.edges;
          return (
        
            <section
              css={css`
                min-height: 100vh;
                margin-top: -0.25rem;
                background: #051937;
              `}
            >
              <Content>
                <h3 css={css`
                
                font-size: 2rem;
                margin-top: 1rem;
                color:white;
                text-align: center;
                `}>Klicka på en bild för att förstora den</h3>
                <CenterImage
                  css={css`

                    height: 100vh;
                    width: 100vw;
                    background: rgba(255,255,255,0.9);
                    cursor: pointer;
                    display: ${this.state.loaded ? 'flex' : 'none'};
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    left: 0;
                    position: fixed;
                  `}
                  pose={this.state.activeImage !== null ? "true" : "false"}
                >
                <button onClick={() => this.handleClick(null)} css={{zIndex: 15,textShadow: "2px 2px 4px #fff;", position: "absolute", right: "5%", top: "5%", cursor: "pointer", fontSize: "3.5rem", border: "none", outline: 0, background: "none" }} >&#10006;</button>
                <button onClick={() => this.changeImage(-1)} css={{[`@media screen and (max-width: ${mobileWidth})`]: {display:"none"}, cursor: "pointer", transform: "rotate(180deg)", fontSize: "3rem", position: "absolute", zIndex: "10", left: "10%", border: "none", outline: 0, background: "none"}}>&#10140;</button>
                <button onClick={() => this.changeImage(1)} css={{[`@media screen and (max-width: ${mobileWidth})`]: {display:"none"}, cursor: "pointer", fontSize: "3rem", position: "absolute", zIndex: "10", right: "10%", border: "none", outline: 0, background: "none"}}>&#10140;</button>
                
                  {this.state.activeImage !== null && <div css={{height: "100%", width: "100%"}} onClick={() => this.changeImage(1)}><Img
                  imgStyle={{borderRadius: "0.5rem",objectFit: "contain", maxHeight: "100%", maxWidth: "100%", height: "auto", width: "auto", left:"50%", top:"50%", transform: "translate(-50%, -50%)"}}

                  alt="Chosen"
                  
                    fluid={this.state.activeImage}
                    css={css`
                    img{
                      width:20rem!important;
                      height:20rem!important;
                    }
                    picture{
                      img{
                        width: auto!important;
                        height: auto!important;
                      }
                    }
                      width: 100%;
                      position: relative;
                      height: 100%;
                      z-index: 5;
                      border-radius: 0.25rem;
                      margin: 0;
                    `}
                  /></div>}
                  <div css={{position: "absolute", zIndex: 1, width:"100%", height: "100%"}} onClick={() => this.handleClick(null)} />
                </CenterImage>
                <div
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    text-align: center;
                    justify-content: space-around;
                  `}
                >
                  {data.allFile.edges.map((image, i) => {
                    return (
                      <div
                      key={i}
                      css={css`
                      width: 30%;
                      margin-top: 2rem;
                     
                      overflow: hidden;
                      
                      @media screen and (max-width: ${mobileWidth}){
                        width:100%;
                      }
                      height: 20rem;
                      cursor: pointer;
                     
                      `} onClick={() => this.handleClick(i)}>
                      <Img
                      fluid={image.node.childImageSharp.fluid}
                        css={css`
                        height: 100%;
                        border-radius: 0.25rem;
                        `}
                      />
                      </div>
                   
                    )
                  })}
                </div>
              </Content>
            </section>
          );
        }}
      />
    )
  }
  componentDidMount() {
      this.setState({ loaded: true })
  }
}

export default Gallery
