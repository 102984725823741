import React from "react"
import { css } from "@emotion/core"
class Content extends React.Component {
  render() {
    return (
      <div
        css={css`
          max-width: 60rem;
          margin-left: auto;
          margin-right: auto;
          padding: 2rem;
        `}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Content
