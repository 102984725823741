import React from "react"
import { css } from "@emotion/core"
import posed from "react-pose"
import Star from "../images/star.png"
import {graphql, StaticQuery} from "gatsby"
import Img from "gatsby-image"

const ShowImage = posed.div({
  show: {
    opacity: 1,
    y: 0,
    transition: { duration: 700 },
  },
  hidden: {
    opacity: 0,
    y: 200,
  },
})
const mobileWidth = '640px'

class Happy extends React.Component {
  imageRef = React.createRef()
  state = { pose: "hidden" }
  data = {
    stars: [
      { x: 5, y: 10 },
      { x: 50, y: 10 },
      { x: 35, y: 2 },
      { x: 87, y: 4 },
      { x: 25, y: 60 },
      { x: 80, y: 24 },
      { x: 60, y: 40 },
    ],
  }
  render() {
    return (
      <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "vera2.png" }) {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
          <section
            css={css`
              min-height: 100vh;
              margin-top: 10rem;
              position: relative;
              overflow: hidden;
            `}
          >

            <ShowImage
              pose={this.state.pose}
              ref={this.imageRef}
              css={css`
                position: relative;
                top: 0;
                height: 30vh;
                width: 100%;
              `}
            >
              {this.data.stars.map((position, i) => {
                return (
                  <img
                  alt="Star"
                    key={i}
                    css={css`
                      position: absolute;
                      width: 2rem;
                      left: ${position.x}%;
                      top: ${position.y}%;
                    `}
                    src={Star}
                  />
                )
              })}
            </ShowImage>
            <div css={css`
            position:relative;
            margin-left: 25rem;
            padding: 1rem;
            @media screen and (max-width: ${mobileWidth}){
              margin-left:0;
              text-align: center;
              margin-top: 0;
            }
            margin-top: 2.5rem;`}>
            <h3 css={css`
            color:white;
            font-size: 3.25rem;
            `}>24/8 2018 02:09</h3>
            <p css={css`
             color:white;
             white-space: pre-line;
             font-size: 1.25rem;`}>
             Vera Wahman landar!
             </p>
             <p css={css`
             color:white;
             white-space: pre-line;
             font-size: 1.25rem;`}>
             En stor omsällning i livet men bara åt det positiva hållet! Vera är lika glad som sina föräldrar och tvingar fram skratt från alla!
             </p>

            </div>
            <ShowImage
              pose={this.state.pose}
              
              css={css`
              @media screen and (max-width: ${mobileWidth}){
                width: 80%;
                margin-left:auto; margin-right: auto;
              }
                width: 20rem;
                position: absolute;
                @media screen and (max-width: ${mobileWidth}){
                position: relative;
                }
                bottom: 0rem;
              `}
            >
              <Img fadeIn={false} critical alt="Vera" fluid={data.file.childImageSharp.fluid} />
            </ShowImage>
          </section>
      )}
      />
    )
  }
  componentDidMount() {
    const imageObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          this.setState({ pose: "show" })
        }
      })
    })

    imageObserver.observe(this.imageRef.current)
  }
}

export default Happy
